import React, { useState } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import { login } from "../redux/slices/authSlice";
import {
  Box,
  Button,
  TextField,
  Typography,
  Card,
  CardContent,
  CircularProgress,
  GlobalStyles, // Importar GlobalStyles
} from "@mui/material";
import { styled } from "@mui/system";

const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100vh",
  width: "100vw",
  backgroundImage: `url(/background.jpg)`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  overflow: "hidden",
}));

const LoginCard = styled(Card)({
  width: "400px",
  padding: "20px",
  textAlign: "center",
  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
});

const CustomButton = styled(Button)({
  backgroundColor: "#ff6600",
  color: "#fff",
  "&:hover": {
    backgroundColor: "#cc5200",
  },
});

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const dispatch = useDispatch();

  const handleLogin = async () => {
    setLoading(true);
    setError("");

    try {
      const credentials = { email, password };
      const response = await axios.post(
        "https://auth.cnseg.bus2ai.com/login",
        credentials
      );
      const { access_token, user } = response.data;

      localStorage.setItem("authToken", access_token);

      dispatch(login(user));

      window.location.href = "/processador";
    } catch (error) {
      console.error("Login failed:", error);
      setError("Credenciais Inválidas, por favor tente novamente");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <GlobalStyles
        styles={{
          html: { margin: 0, padding: 0, width: "100%", height: "100%" },
          body: { margin: 0, padding: 0, width: "100%", height: "100%" },
          "#root": { width: "100%", height: "100%" },
        }}
      />
      <Container>
        <LoginCard>
          <CardContent>
            <Typography variant="h5" gutterBottom>
              Login
            </Typography>
            {error && (
              <Typography color="error" sx={{ marginBottom: "10px" }}>
                {error}
              </Typography>
            )}
            <TextField
              label="Email"
              type="email"
              fullWidth
              variant="outlined"
              margin="normal"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              label="Senha"
              type="password"
              fullWidth
              variant="outlined"
              margin="normal"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <CustomButton
              fullWidth
              variant="contained"
              sx={{ marginTop: "20px" }}
              onClick={handleLogin}
              disabled={loading}
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Entrar"
              )}
            </CustomButton>
          </CardContent>
        </LoginCard>
      </Container>
    </>
  );
};

export default LoginPage;

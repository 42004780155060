import React, { useState } from "react";
import Dropzone from "react-dropzone";
import { useDispatch } from "react-redux";
import { logout } from "../redux/slices/authSlice";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Box,
  Paper,
  Container,
  CircularProgress,
  Modal,
  TextField,
  Backdrop,
  Fade,
  Drawer,
  List,
  Divider,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemText,
  Snackbar
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import LogoutIcon from "@mui/icons-material/Logout";
import { styled } from "@mui/system";
import "highlight.js/styles/atom-one-dark.css";
import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import axiosInstance from "../utils/axiosInstance";
import AssignmentIcon from '@mui/icons-material/Assignment';
import DeleteIcon from '@mui/icons-material/Delete';
import BuildIcon from '@mui/icons-material/Build';
import SaveIcon from '@mui/icons-material/Save';

// Estilos personalizados

const StyledAppBar = styled(AppBar)({
  backgroundColor: "#ff6600", // Cor do Bradesco
  padding: "10px",
});

const CustomButton = styled(Button)({
  backgroundColor: "#ff6600",
  color: "#ffffff",
  borderRadius: "20px",
  padding: "10px 20px",
  "&:hover": {
    backgroundColor: "#cc5200",
  },
});


const ProcessadorPage = () => {
  const dispatch = useDispatch();
  const [selectedFile, setSelectedFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const [markdownResult, setMarkdownResult] = useState("");
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success"); // "error", "info"

  const showSnackbar = (message, severity = "success") => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  // Estado para o modal de edição de regras
  const [openModal, setOpenModal] = useState(false);
  const [rulesText, setRulesText] = useState("");
  const [loadingRules, setLoadingRules] = useState(false);
  // Estado para a barra retrátil
  const [drawerOpen, setDrawerOpen] = useState(false);


  const handleDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    setSelectedFile(file);
    setPreview(URL.createObjectURL(file));
    setMarkdownResult(""); // Limpa o markdown ao selecionar um novo arquivo
  };

  const handleProcessFile = async () => {
    if (!selectedFile) {
      showSnackbar("Selecione um arquivo primeiro!");
      return;
    }

    setLoading(true);
    const formData = new FormData();
    formData.append("file", selectedFile);

    try {
      const response = await axiosInstance.post("/process-file", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      setMarkdownResult(response.data.result);
    } catch (error) {
      console.error("Erro ao processar o arquivo:", error);
      setMarkdownResult("Erro ao processar o arquivo.");
    } finally {
      setLoading(false);
    }
  };

  const handleClear = () => {
    setSelectedFile(null);
    setPreview(null);
    setMarkdownResult("");
  };

  // Função para buscar as regras atuais do backend
  const handleOpenModal = async () => {
    setOpenModal(true);
    setLoadingRules(true);

    try {
      const response = await axiosInstance.get("/getParam");
      if (response.status === 200) {
        setRulesText(response.data.content);
      } else {
        showSnackbar("Erro ao buscar as regras atuais.");
      }
    } catch (error) {
      console.error("Erro ao buscar as regras:", error);
      showSnackbar("Não foi possível carregar as regras. Tente novamente.");
    } finally {
      setLoadingRules(false);
    }
  };

  // Função para salvar as regras editadas no backend
  const handleSaveRules = async () => {
    try {
      const response = await axiosInstance.post(
        "/saveParam",
        rulesText,

      );
      if (response.status === 200) {
        showSnackbar("Regras atualizadas com sucesso!");
        setOpenModal(false);
      } else {
        showSnackbar("Erro ao atualizar as regras.");
      }
    } catch (error) {
      console.error("Erro ao atualizar as regras:", error);
      showSnackbar("Não foi possível salvar as regras. Tente novamente.");
    }
  };

  const handleLogout = () => {

    // clear token from local storage
    localStorage.removeItem("authToken");

    // Dispatch the logout action
    dispatch(logout());

    // Redirect to the login page
    window.location.href = "/";
  };


  const MainContainer = styled(Container)({
    marginTop: "90px",
    paddingBottom: "10px",
  });

  const FlexContainer = styled(Box)({
    display: "flex",
    justifyContent: "space-around",
    alignItems: "flex-start",
    gap: "5px",
    flexWrap: "wrap",
    width: "90%", // Ocupa 90% da largura disponível
    margin: "auto",
  });

  const ContentBox = styled(Box)({
    width: "100%",
    maxWidth: "45%", // Ocupa 45% do espaço, deixando 90% ao todo
    padding: "20px",
  });

  return (

    <>
      {/* Barra Superior */}
      <StyledAppBar position="fixed">
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="menu"
            edge="start"
            onClick={() => setDrawerOpen(true)}
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" sx={{ color: "#fff", flexGrow: 1 }}>
            Processador de Imagens
          </Typography>
          <CustomButton
            onClick={handleOpenModal}
            variant="outlined"
            color="secondary"
            sx={{
              ml: 'auto',
              whiteSpace: 'nowrap',
              '@media (max-width:600px)': {
                fontSize: '0.75rem',
                padding: '4px 8px',
              },
            }}
          >
            <AssignmentIcon sx={{ marginRight: 1 }} ></AssignmentIcon>
            Editar Regras
          </CustomButton>
        </Toolbar>
      </StyledAppBar>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        severity={snackbarSeverity} />

      {/* Drawer - Barra retrátil */}
      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        sx={{
          "& .MuiDrawer-paper": {
            width: { xs: 200, sm: 250 },
            overflow: "hidden", // Remove scroll lateral
          },
        }}
      >
        <Box sx={{ width: "100%", height: "100%" }}>
          <List>
            <ListItem disablePadding>
              <ListItemButton
                onClick={() => {
                  window.location.href = "/processador"; // Redirect to Processador page
                }}
              >
                <ImageSearchIcon sx={{ marginRight: 2 }} />
                <ListItemText
                  primary="Processador de Imagens"
                />
              </ListItemButton>
            </ListItem>
          </List>
          <Divider />
          <Box
            sx={{
              textAlign: "center",
              position: "absolute",
              bottom: 0,
              width: "100%",
              padding: "10px",
            }}
          >
            <Button
              startIcon={<LogoutIcon />}
              variant="outlined"
              color="error"
              onClick={handleLogout}
            >
              Logout
            </Button>
          </Box>
        </Box>
      </Drawer>


      {/* Contêiner Principal */}
      <MainContainer>
        {/* Contêiner Flexível para o Upload e o Resultado */}
        <FlexContainer>
          {/* Área de Upload */}
          <ContentBox>
            <Typography variant="h5" gutterBottom sx={{ marginBottom: "20px", fontWeight: "bold", textAlign: "center" }}>
              Carregue sua Imagem
            </Typography>
            <Dropzone
              onDrop={handleDrop}
              accept={{ "image/*": [".png", ".jpg", ".jpeg"] }}
            >
              {({ getRootProps, getInputProps }) => (
                <Paper
                  {...getRootProps()}
                  sx={{
                    border: "2px dashed #d4001a",
                    padding: "20px",
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "550px",
                    backgroundColor: "#f7f7f7",
                  }}
                >
                  <input {...getInputProps()} />
                  {selectedFile ? (
                    <img
                      src={preview}
                      alt="Preview"
                      style={{ maxWidth: "100%", maxHeight: "100%" }}
                    />
                  ) : (
                    <Typography>
                      Arraste uma imagem ou clique para selecionar
                    </Typography>
                  )}
                </Paper>
              )}
            </Dropzone>
          </ContentBox>

         {/* Exibição do Markdown */}
<ContentBox>
  <Typography 
    variant="h5" 
    gutterBottom 
    sx={{ 
      marginBottom: "20px", 
      fontWeight: "bold", 
      textAlign: "center" 
    }}
  >
    Resultado
  </Typography>
  {loading ? (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "550px", // Mesmo tamanho da Dropzone
      }}
    >
      <CircularProgress size={60} sx={{ color: "#d4001a" }} />
    </Box>
  ) : markdownResult ? (
    <Paper
      sx={{
        height: "550px", // Mesmo tamanho da Dropzone
        padding: "20px",
        borderRadius: "5px",
        backgroundColor: "#2d2d2d",
        color: "#fff",
        overflowY: "auto", // Adiciona scroll vertical
        whiteSpace: "pre-wrap", // Mantém quebras de linha no JSON
        fontSize: "0.875rem",
        wordWrap: "break-word", // Previne que palavras longas ultrapassem o limite
      }}
    >
      {typeof markdownResult === "string" 
        ? markdownResult 
        : JSON.stringify(markdownResult, null, 2)}
    </Paper>
  ) : (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#f7f7f7",
      }}
    >
      <Typography>Nenhum resultado a exibir.</Typography>
    </Box>
  )}
</ContentBox>
        </FlexContainer>

        {/* Botões de Processar, Limpar Tudo */}
        <Box sx={{ display: "flex", justifyContent: "center", gap: 1, mt: 4 }}>
          <CustomButton
            onClick={handleProcessFile}
            variant="contained"
            disabled={loading}
          >
            <BuildIcon sx={{ marginRight: 1 }}></BuildIcon>
            {loading ? "Processando..." : "Processar"}

          </CustomButton>
          <CustomButton onClick={handleClear} variant="outlined" color="secondary">
            <DeleteIcon sx={{ marginRight: 1 }}></DeleteIcon>
            Limpar Tudo
          </CustomButton>
        </Box>
      </MainContainer>

      {/* Modal para Editar Regras */}
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModal}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "80%",
              bgcolor: "background.paper",
              border: "2px solid #d4001a",
              boxShadow: 24,
              p: 4,
            }}
          >
            <Typography variant="h6" component="h2" gutterBottom>
              Editar Regras
            </Typography>
            {loadingRules ? (
              <Box sx={{ textAlign: "center" }}>
                <CircularProgress />
              </Box>
            ) : (
              <TextField
                multiline
                rows={18}
                fullWidth
                variant="outlined"
                value={rulesText}
                onChange={(e) => setRulesText(e.target.value)}
              />
            )}
            <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>

              <CustomButton
                onClick={handleSaveRules}
                variant="contained"
                color="primary"
              >
                <SaveIcon sx={{ marginRight: 1 }}></SaveIcon>
                Salvar
              </CustomButton>
            </Box>
          </Box>
        </Fade>
      </Modal>

      {/* Rodapé */}
      <Box
        component="footer"
        sx={{
          textAlign: "center",
          padding: "10px",
          backgroundColor: "#333",
          color: "#fff",
          mt: 4,
        }}
      >
        <Typography variant="body2">
          &copy; {new Date().getFullYear()} Bus2AI. Todos os direitos reservados.
        </Typography>
      </Box>
    </>
  );
};

export default ProcessadorPage;
